import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import MenuData from "../content/Menus.json";
import Footer from "../components/footer";

function SuccessPage() {
  return (
    <Layout menus={MenuData}>
      <SEO
        title="Contact"
        keywords={[`data`, `science`, `consulting`]}
      />
      <div>
        <section className="hero container section mx-auto">
          <div className="container section mx-auto">
            <h2 className="font-thin ">Thanks for getting in touch! We'll get back to you asap... </h2>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export default SuccessPage;
